import { makeStyles } from "@material-ui/core";

const appStyles = makeStyles((theme) => ({
    app: {
        flexGrow: 1,
        textAlign: "center"
    },
    appContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px",
        color: "white"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    toolbar: {
        backgroundColor: "#0c243c"
    }
}));

export default appStyles;