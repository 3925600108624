import { AppBar, Box, Grid, makeStyles, Paper, Slider, Typography } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Timer from "./Timer";

const useStyles = makeStyles((theme) => ({
    tabs: {
        top: 'auto',
        bottom: 0,
    }
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (<> {children} </>)}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}


export default function TabsMenu() {
    const classes = useStyles();
    const [tab, setTab] = useState(0);

    // Tab controls
    const changeTab = (event: ChangeEvent<{}>, newTab: number) => {
        setTab(newTab);
    };

    return (<>
        <AppBar className={classes.tabs} position="static">
            <Tabs
                value={tab}
                onChange={changeTab}
                aria-label="simple tabs example"
                variant="fullWidth"
                indicatorColor="primary"
                textColor="secondary"
                centered
            >
                <Tab label="Rest Timer" {...a11yProps(0)} />
                <Tab label="Interval" {...a11yProps(1)} />
                <Tab label="HIIT" {...a11yProps(2)} />
            </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0}>
            <Timer />
        </TabPanel>
        <TabPanel value={tab} index={1} >
            Interval
        </TabPanel>
        <TabPanel value={tab} index={2} >
            Item Three
        </TabPanel>
    </>
    );
}