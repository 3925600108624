import { createMuiTheme } from "@material-ui/core";

const appTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#415c77',
            main: '#123456',
            dark: '#0c243c',
            contrastText: '#FFFFFF',
        },
        secondary: {
            light: '#ffffff',
            main: '#ffffff',
            dark: '#b2b2b2',
            contrastText: '#123456',
        },
    },
});

export default appTheme;