import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, PropTypes } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

export default function InfoDialog(props: any) {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="info-dialog-title">
      <DialogTitle id="simple-dialog-title">Info</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This fitness timer was implemented by Dominik Bilgery. <br />
          The source code is open source available on GitHub.
          </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
          </Button>
      </DialogActions>
    </Dialog>
  );
}

