import { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Drawer, MuiThemeProvider } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TabsMenu from './components/TabsMenu'

import InfoDialog from './components/InfoDialog';
import appStyles from './AppStyles';
import appTheme from './AppTheme';
import './AppStyles.tsx';

export interface InfoDialogProps {
  open: boolean;
  onClose: (value: string) => void;
}

export default function App() {
  const classes = appStyles();

  // Global App States
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);

  // Info Dialog controls
  const openInfoDialog = () => {
    setInfoDialogOpen(true);
  };
  const closeInfoDialog = (value: any) => {
    setInfoDialogOpen(false);
  };

  // Menu Drawer controls
  const openMenuDrawer = () => {
    setMenuDrawerOpen(true);
  }
  const closeMenuDrawer = () => {
    setMenuDrawerOpen(false);
  }

  return (
    <MuiThemeProvider theme={appTheme}>
      <div className={classes.app}>
        <AppBar position="static" className={classes.toolbar}>
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon onClick={openMenuDrawer} />
              <Drawer anchor={"left"} open={menuDrawerOpen} onClose={closeMenuDrawer}>
                Menu
              </Drawer>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Fitness Timer
          </Typography>
            <Button color="inherit"><InfoOutlinedIcon onClick={openInfoDialog} /></Button>
          </Toolbar>
        </AppBar>
        <main className={classes.appContent}>
          <TabsMenu />
        </main>
        <InfoDialog open={infoDialogOpen} onClose={closeInfoDialog} />
      </div>
    </MuiThemeProvider >
  );
}
