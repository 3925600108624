import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, makeStyles, Slider, Snackbar, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    timerButton: {
        color: "#123456",
        fontWeight: "bold",
        fontSize: "3em",
        borderRadius: "50%",
        border: "1px solid #123456",
        background: "none",
        width: "200px !important",
        height: "200px !important"
    },
    slider: {
        marginTop: "50px",
        width: "350px",
        marginBottom: "50px"
    },
    control: {
        padding: theme.spacing(2),
    },
    notificationSnackbar: {
        backgroundColor: '#123456',
    },
}));

// Notification Snackbar
function Notification(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Timer() {
    const classes = useStyles();
    const [timeLeft, setTimeLeft] = useState(30);
    const [isRunning, setIsRunning] = useState(false);
    const [notificationSnackOpen, setNotificationSnackOpen] = useState(false);
    const [timerValue, setTimerValue] = useState(30);

    // Countdown control
    useEffect(() => {
        if (isRunning) {
            if (timeLeft >= 0) {
                // decrease countdown time
                const intervalID = window.setInterval(() => { setTimeLeft(timeLeft => timeLeft - 1); }, 1000);
                return () => window.clearInterval(intervalID);
            } else {
                // reset countdown
                setTimeLeft(timerValue);
                setIsRunning(false);
                setNotificationSnackOpen(true);
            }
        }
    }, [timeLeft, isRunning, timerValue]);

    // Notification controls
    const notificationOpen = () => {
        setNotificationSnackOpen(true);
    };
    const notificationClose = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotificationSnackOpen(false);
    };

    // Rest timer values
    const timers = [
        {
            value: 30,
            label: '30s',
        },
        {
            value: 60,
            label: '60s',
        },
        {
            value: 90,
            label: '90s',
        },
        {
            value: 120,
            label: '120s',
        },
        {
            value: 150,
            label: '150s',
        },
        {
            value: 180,
            label: '180s',
        },
    ];

    function timerValues(t: number) {
        return `${t}s`;
    }

    const timerChange = (event: any, newValue: any) => {
        setTimerValue(newValue)
        setTimeLeft(newValue)
    }

    return (<>
        <Slider
            disabled={isRunning} value={timerValue} onChange={timerChange} marks={timers} min={30} max={180}
            className={classes.slider} getAriaValueText={timerValues} valueLabelFormat={timerValues} step={null} valueLabelDisplay="auto" /> <br />
        <Box position="relative" display="inline-flex">
            <CircularProgress value={(timeLeft / timerValue) * 100} variant="determinate" thickness={1} className={classes.timerButton} size="12rem" />
            <Snackbar open={notificationSnackOpen} autoHideDuration={6000} onClose={notificationClose}>
                <Notification onClose={notificationClose} severity="success" className={classes.notificationSnackbar}>
                    Ready!
            </Notification>
            </Snackbar>
            <Box onClick={() => { isRunning ? setIsRunning(false) : setIsRunning(true) }}
                top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h5" component="div" color="primary">{timeLeft}</Typography>
            </Box>
        </Box></>)
}